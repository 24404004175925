import * as React from 'react'
import {Link} from 'gatsby'
import BGImage from "../images/clean-kitchen.jpg"
import BGImageM from "../images/clean-kitchen.jpg"
import Kitchen from "../images/kitchen.jpg"
import BackgroundImage from "../components/background-image";
import GlanceInfo from "../components/glance-info";
import CustomerService from "../components/customer-service";
import Nav from "../components/nav"
import Footer from "../components/footer"
import CtaBar from "../components/cta-bar";
import Button from "../components/button";
import ReviewSection from "../components/review-section";
import ImageGalleryShowcase from "../components/image-gallery-showcase";
import Services from "../components/services";
import DarkModeToggle from "../components/dark-mode-toggle";
import AboutBusiness from "../components/about-business";
import AdditionalServices from "../components/additional-services";
import BeforeAndAfter from "../components/before-and-after";
import SimplePhotoAndText from "../components/simple-photo-and-text";
import MoreBusinessInfo from "../components/more-business-info";
import CtaBarAlternate from "../components/Cta-bar-alternate";
import ToiletPaperRollImage from "../images/toilet-paper.jpg"
import Interior from "../images/placeholder.jpg"
import {Helmet} from "react-helmet";
import PopUp from "../components/PopUp";

//npx gatsby develop

const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>Rancho Bernardo Maids - Professional Home Cleaning Services</title>
                <meta name="description" content="Rancho Bernardo Maids offers the best cleaning services, deep cleans, & move outs for houses, apartments, condos & more" />
                <meta name="keywords" content="Deep Cleaning, Standard House Cleaning, Move Outs, Party Clean Ups"/>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <main>


                <Nav/>
                <BackgroundImage image={BGImage} mobileImage={BGImageM} alt={"forest background"} height={"h-128"} backgroundHeight={"h-152"}>
                    <div className={"h-full flex flex-col justify-center items-center max-w-lg mx-auto"}>
                        <h1 className={"text-5xl text-center text-white p-6"}>Home Cleaning Services in San Diego</h1>
                        <p className={"text-md text-center text-white p-6"}>Rancho Bernardo Maids offers
                            the best cleaning services, deep
                            cleans, & move outs for houses,
                            apartments, condos & more</p>
                        <div className={"flex flex-row"}>
                            <Link href={"contact"}><button className={"Lg:m-6 m-2 lg:mt-8 bg-red-500 hover:bg-gray-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Get Your Quote!</button></Link>
                            <a href={"tel:8587769914"}><button className={"Lg:m-6 m-2 lg:mt-8 bg-malibu-500 hover:bg-gray-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Call Now!</button></a>
                        </div>

                    </div>
                </BackgroundImage>
                <GlanceInfo />
                <AboutBusiness />
                <CtaBar/>
                <Services/>
                <SimplePhotoAndText/>
                <BackgroundImage image={Interior} mobileImage={Interior} alt={"a kitchen with beautifully painted cabinets"} height={"h-128"} fixed={true}>
                    <div className={"flex flex-row flex-wrap h-full items-center justify-evenly max-w-5xl mx-auto text-white"}>
                        <div className={"max-w-[15rem]"}>
                            <h1 className={"text-2xl"}><span className={"text-4xl text-malibu-500 font-bold"}>10+</span> Years</h1>
                            <p className={"mt-2"}>Experience cleaning homes and condos across the North County area</p>
                        </div>
                        <div className={"max-w-[15rem]"}>
                            <h1 className={"text-2xl"}><span className={"text-4xl text-malibu-500 font-bold"}>Local+</span> Business</h1>
                            <p className={"mt-2"}>We are a local business proudly owned and operated right here in town.</p>
                        </div>
                        <div className={"max-w-[15rem]"}>
                            <h1 className={"text-2xl"}><span className={"text-4xl text-malibu-500 font-bold"}>100%</span> Satisfaction</h1>
                            <p className={"mt-2"}>We don't settle for mediocre - we're satisfied only when you're 100% satisfied.</p>
                        </div>
                    </div>

                </BackgroundImage>
                <MoreBusinessInfo/>
                <BeforeAndAfter/>
                <CtaBar/>
                <ReviewSection/>
                <div className={"bg-black flex justify-center"}>
                    <img src={ToiletPaperRollImage} alt={"toilet paper roll"} className={"lg:w-[500px] h-full w-full"} />
                </div>
                <CtaBarAlternate/>























            </main>
            <Footer />
        </>
    )
}

export default IndexPage